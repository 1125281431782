import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { auth, db, logout } from "../config/firebase-config"
import { doc, query, collection, getDocs, where, updateDoc} from "firebase/firestore";
import Header from '../components/header';
import '../styles/dashboard.css'

export default function Dashboard() {
  const [user, loading, error] = useAuthState(auth);
  const [name, setName] = useState("");
  const [email,setEmail] = useState("");
  const [account,setAccount] = useState("");
  const [accountLink,setAccountLink] = useState("");
  const [loginLink,setLoginLink] = useState("");
  // const api = "https://us-central1-payme-eb477.cloudfunctions.net/app";
  const api = "https://rocky-eyrie-95465.herokuapp.com";
  // const api = "http://localhost:5001/payme-eb477/us-central1/app";
  const navigate = useNavigate();




  const fetchUserInfo = async () => {
    try {
      const q = query(collection(db, "restaurants"), where("uid", "==", user?.uid));

      const docz = await getDocs(q);
      if(docz.docs.length === 0){
        return navigate("/")
      }
      const data = docz.docs[0].data();
      setName(data.name);
      setEmail(data.email);

      if(data.account_id !== ""){
        setAccount(data.account_id);
        checkAccountID(data.account_id);
      } else{
        const response = await fetch(api + '/create-account', {
          method:"POST",
          headers:{"Content-Type":"application/json"},
          body: JSON.stringify(data)
        })

        if(response.ok){
          const res = await response.json();
          console.log(res);
          const userDocRef = doc(db,'restaurants',docz.docs[0].id)
          await updateDoc(userDocRef, {
            account_id:res.id
          })
      }
      }
    } catch (err) {
      console.error(err);
      alert("An error occured while fetching user data");
    }
  };

  const checkAccountID = async(account_id) => {
    const data = {
      account_id: account_id
    }

    const response = await fetch(api + '/check-account', {
      method:"POST",
      headers:{"Content-Type":"application/json"},
      body:JSON.stringify(data)
    })

    if(response.ok){
      const res = await response.json();

      if(res.payouts_enabled){
        generateLoginLink(res.id);
      } else{
        generateAccountLink(res.id);
      }
    }
  }


  const generateLoginLink = async (account_id) =>{
    const data = {
      account_id: account_id
    }

    const response = await fetch(api + '/login-link', {
      method:"POST",
      headers:{"Content-Type":"application/json"},
      body:JSON.stringify(data)
    })

    if(response.ok){
      const res = await response.json();
      console.log(res);
      setLoginLink(res.url);
    }
  }


  const generateAccountLink = async (account_id) =>{
    const data = {
      account_id:account_id
    }

    const response = await fetch(api + '/account-link',{
      method:"POST",
      headers:{"Content-Type":"application/json"},
      body:JSON.stringify(data)
    })

    if(response.ok){
      const res = await response.json();
      console.log(res);
      setAccountLink(res.url);
    }
  }

  const navigateToAccountLink = async () =>{
    window.location.href = accountLink;
  }

  const navigateToLoginLink = async () =>{
    window.location.href = loginLink;
  }



  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/");
    // console.log(user);
    fetchUserInfo();
  }, [user, loading]);

  const previewStyle = {
    height: 240,
    width: 300,
  }

  return (
    <div className="dashboard-restaurant-container">
      <Header />
       <div className="dashboard-restaurant-content">
        <div className="welcome-restaurant-content">
            <h1>Welcome {name}</h1>
            <p>Email: {user?.email}</p>
        </div>

        <div className="dashboard-login-btn">
          {accountLink === "" && <button className="login-button margin-top" onClick={navigateToLoginLink} href={loginLink}>Dashboard</button>}
          {accountLink !== "" && <button className="login-button no-margin-bottom" onClick={navigateToAccountLink} href={accountLink}>Set Up Payments</button>}
        </div>

        <button className="login-button no-margin-top" onClick={logout}>
          Logout
         </button>
       </div>
     </div>
  )
}
