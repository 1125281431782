import React, { useState } from 'react';
import '../styles/ChargeList.css';

function ChargeList({ charges, pageSize = 10 }) {

  const formatDate = (inputSeconds) =>{
    const date = new Date(0); // Create a new Date object with the Unix epoch (January 1, 1970) as the starting point
    date.setUTCSeconds(inputSeconds); // Set the date to the specified number of seconds since the Unix epoch
    const month = date.getUTCMonth() + 1; // Get the month (0-11) and add 1 to convert to (1-12) format
    const day = date.getUTCDate(); // Get the day of the month (1-31)
    const year = date.getUTCFullYear(); // Get the year (4 digits)
    let hours = date.getUTCHours(); // Get the hours (0-23)
    const minutes = date.getUTCMinutes(); // Get the minutes (0-59)
    const ampm = hours >= 12 ? 'PM' : 'AM'; // Determine whether it's AM or PM
    hours = hours % 12; // Convert from 24-hour to 12-hour format
    hours = hours ? hours : 12; // If the hours are 0, set them to 12 instead
    const formattedDateTime = `${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}-${year} ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${ampm}`; // Format the date as MM-DD-YYYY hh:MM AM/PM
    return formattedDateTime;
  }

  const formatPrice = (price) =>{
    return `$${(price * .01).toFixed(2)}`;
}

const totalSpend = (charges)=>{
  const spend = charges.reduce((total, transaction) => {
    return total + transaction.amount;
  }, 0);
  return spend;
}

totalSpend(charges);



  return (
        <div className="transaction-container">
        {charges.map((transaction) => (
          <div className="transaction">
              {/* <p>ID: {transaction.id}</p> */}
              <p className="amount-text">{formatPrice(transaction.amount)}</p>
              <p className="date-text">{formatDate(transaction.created)}</p>
          </div>
        ))}
        </div>
  );
}

export default ChargeList;