import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { auth, db, logout } from "../config/firebase-config"
import { doc, query, collection, getDocs, where, updateDoc} from "firebase/firestore";
import Header from '../components/header';
import '../styles/insights.css'


export default function Insights() {
  const [user, loading, error] = useAuthState(auth);
  const [name, setName] = useState("");
  const [email,setEmail] = useState("");
  const [account,setAccount] = useState("");
  const [customer,setCustomer] = useState("");
  const [accountLink,setAccountLink] = useState("");
  const [loginLink,setLoginLink] = useState("");
  const [transactions,setTransactions] = useState([]);
  const navigate = useNavigate();
  // const api = "https://us-central1-payme-eb477.cloudfunctions.net/app";
  // const api = "http://localhost:5001/payme-eb477/us-central1/app";
  const api = "https://rocky-eyrie-95465.herokuapp.com";


  const fetchUserInfo = async () => {
    try {
      const q = query(collection(db, "restaurants"), where("uid", "==", user?.uid));

      const docz = await getDocs(q);
      if(docz.docs.length === 0){
        return navigate("/login")
      }
      const data = docz.docs[0].data();
      setName(data.name);
      setEmail(data.email);

      if(data.account_id !== ""){
        setAccount(data.account_id);
      } else{
        console.log("could not find the customer info")
      }
    } catch (err) {
      console.error(err);
      alert("An error occured while fetching user data");
    }
  };


  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/");
    fetchUserInfo();
  }, [user, loading]);

  return (
    <div className="insights-container">
      <Header />

      <div className="insights-content">
        {/* <div className="insights-card">
            <p>Alex spends 20% more when he is personally invited by the bartender to try an exclusive off-menu cocktail.</p>
        </div>
        <div className="insights-card">
            <p>Thomas spends 25% more on drinks when he is given priority access to exclusive events or tastings at your bar.</p>
        </div> */}
        {/* <div className="insights-card">
            <p>After two three other high-end bars in a night, Jack is 50% more likely to order a top-shelf spirit at your bar when charged $18 vs $20</p>
        </div> */}
        <div className="insights-card">
            <p>Dan's spending at your establishment is 30% lower compared to other high-end bars; his average spend is $125 when drinks are priced at $15 vs $37 when drinks are priced at $17</p>
        </div>
        <div className="insights-card">
            <p>Alex is a social butterfly and often buys rounds for his friends. On average, he orders a round of 5 drinks every hour. If Alex is provided with a free drink when buying the second round of the night, there is a 58% chance he will order a third round.</p>
        </div>
        <div className="insights-card">
            <p>Your bar experiences a 40% increase in foot traffic compared to similar venues during the 1 AM rush. Capitalize on this advantage by ensuring optimal staffing levels</p>
        </div>
        <div className="insights-card">
            <p>Consumers purchase 50% more tequila at your establishment compared to other high-end bars. Highlight your premium tequila selection and consider hosting exclusive tequila tasting events</p>
        </div>
      </div>

     </div>
  )
}


