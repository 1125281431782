import Reach, { useState, useEffect } from "react";
import "../styles/header.css"
import { Link, useNavigate, Redirect } from "react-router-dom";
import { auth, db, logInWithEmailAndPassword, signInWithGoogleRestaurant, logout } from "../config/firebase-config";
import { doc, query, collection, getDocs, where, updateDoc} from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";

export default function Header(){
  const [user, loading, error] = useAuthState(auth);
  const [completed, setCompleted] = useState(false);
  const [authCheck, setAuthCheck] = useState(false);
  const [adminCheck, setAdminCheck] = useState(false)

  const changeState = () =>{
    setCompleted(!completed);
  }

  const fetchUserInfo = async () => {
    try {
      const q = query(collection(db, "users"), where("uid", "==", user?.uid));
      const docz = await getDocs(q);
      const data = docz.docs[0].data();

      console.log(data.beta_test);

      if(data.beta_test === true){
        setAdminCheck(true);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (loading) return;
    if (user) setAuthCheck(true)
  }, [user, loading]);



  return(

    <div className="header-container">
      <div className={completed ? 'header active' : "header"}>
        <svg onClick={changeState} id="menu-icon" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 348.33 149.26">
          <rect  className={completed ? 'menu close' : "menu open"} id="top" width="348.33" height="28.7"/>
          <rect  className={completed ? 'menu close' : "menu open"} id="middle" y="60.28" width="348.33" height="28.7"/>
          <rect  className={completed ? 'menu close' : "menu open"} id="bottom" y="120.56" width="348.33" height="28.7"/>
        </svg>
        <h1><Link className="new-font" to="/">Falcon Pay for Bars</Link></h1>
        {/* <h1>Falcon Pay</h1> */}
      </div>

      <div className={completed ? 'header-container-m show' : "header-container-m inactive"}>
        <div className="menu-content">
          <ul>
            {/* <li> <Link to="/">Home</Link></li> */}
            {authCheck === true &&  <li onClick={changeState}> <Link to="/dashboard">Dashboard</Link></li>}
            {authCheck === false &&  <li onClick={changeState}> <Link to="/">Login/Register</Link></li>}
            {authCheck === true &&  <li onClick={changeState}> <Link to="/payment">Accept Payments</Link></li>}
            {authCheck === true &&  <li onClick={changeState}> <Link to="/coat-payment">Coat Check</Link></li>}
            {authCheck === true &&  <li onClick={changeState}> <Link to="/event-scan">Event Scan</Link></li>}
            {authCheck === true &&  <li onClick={changeState}> <Link to="/insights">Insights</Link></li>}
            {authCheck === true &&  <li onClick={changeState}> <Link to="/transactions">Transactions</Link></li>}
            {/* <li onClick={changeState}> <Link to="/faq">FAQ</Link></li> */}
            <li onClick={changeState}> <Link to="/privacy">Privacy Policy</Link></li>
            {authCheck === true &&  <li onClick={changeState}> <a onClick={logout}>Logout</a></li>}
          </ul>
        </div>
      </div>
    </div>
  )
}