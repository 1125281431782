import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { auth, db, logout } from "../config/firebase-config"
import { doc, query, collection, getDocs, where, updateDoc} from "firebase/firestore";
import Header from '../components/header';
import '../styles/transactions.css'
import ChargeList from '../components/ChargeList';


export default function Dashboard() {
  const [user, loading, error] = useAuthState(auth);
  const [name, setName] = useState("");
  const [email,setEmail] = useState("");
  const [account,setAccount] = useState("");
  const [customer,setCustomer] = useState("");
  const [accountLink,setAccountLink] = useState("");
  const [loginLink,setLoginLink] = useState("");
  const [transactions,setTransactions] = useState([]);
  const navigate = useNavigate();
  // const api = "https://us-central1-payme-eb477.cloudfunctions.net/app";
  // const api = "http://localhost:5001/payme-eb477/us-central1/app";
  const api = "https://rocky-eyrie-95465.herokuapp.com";


  const fetchUserInfo = async () => {
    try {
      const q = query(collection(db, "restaurants"), where("uid", "==", user?.uid));

      const docz = await getDocs(q);
      if(docz.docs.length === 0){
        return navigate("/login")
      }
      const data = docz.docs[0].data();
      setName(data.name);
      setEmail(data.email);

      if(data.account_id !== ""){
        setAccount(data.account_id);
        fetchCharges(data.account_id)
      } else{
        console.log("could not find the customer info")
      }
    } catch (err) {
      console.error(err);
      alert("An error occured while fetching user data");
    }
  };


  
const refund = async (id) =>{
    const data = {
        charge_id: id
    }

    try{
      const response = await fetch(api + '/refund', {
        method:"POST",
        headers:{"Content-Type":"application/json"},
        body: JSON.stringify(data)
       })
    } catch(err){
        console.log(err);
    }
}

const formatPrice = (price) =>{
    return `$${(price * .01).toFixed(2)}`;
}

const creation_time = (time)=>{
    const newTime = new Date(time * 1000).toISOString().slice(0, 19).replace('T', ' ');
    return newTime;
}

  const fetchCharges = async (account_id) =>{

    const data = {
      account:account_id
    }

    try{
      const response = await fetch(api + '/fetch-charges', {
        method:"POST",
        headers:{"Content-Type":"application/json"},
        body: JSON.stringify(data)
      })
      if(response.ok){
        const res = await response.json();
        console.log(res);
        setTransactions(res.data);
      }
    } catch(err){
      console.log(err);
    }
  }

  
  const totalSpend = (charges)=>{
    // console.log(charges);
    const spend = charges.reduce((total, transaction) => {
      return total + transaction.amount;
    }, 0);
    return spend;
  }

  const totalFee = (charges)=>{
    // console.log(charges);
    const spend = charges.reduce((total, transaction) => {
      return total + transaction.source_transaction.application_fee_amount;
    }, 0);
    return spend;
  }

  const totalNet = (charges)=>{
    // console.log(charges);
    const spend = charges.reduce((total, transaction) => {
      return total + (transaction.amount - transaction.source_transaction.application_fee_amount);
    }, 0);
    return spend;
  }



  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/");
    fetchUserInfo();
  }, [user, loading]);

  return (
    <div className="transactions-container">
      <Header />

      {/* <PaymentChart transactions={transactions ?? []} /> */}

      <div className="summary-view">

        <div className="summary-card">
          <p className="amount-text">{formatPrice(totalSpend(transactions))}</p>
          <p className="date-text">Total Processed </p>
        </div>
        <div className="summary-card">
          <p className="amount-text">{formatPrice(totalFee(transactions))}</p>
          <p className="date-text">Total Fees </p>
        </div>
        <div className="summary-card">
          <p className="amount-text">{formatPrice(totalNet(transactions))}</p>
          <p className="date-text">Total Net </p>
        </div>

      </div>

      <ChargeList charges={transactions} pageSize={10} />

      {/* <button onClick={clickme}>Click</button> */}
     </div>
  )
}


