import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { auth, db, logout } from "../config/firebase-config"
import { doc, query, collection, getDocs, where, updateDoc} from "firebase/firestore";
import QRCode from "react-qr-code";
// import QrReader from 'react-qr-scanner';
import QrReader from 'modern-react-qr-reader';
import Header from '../components/header';
import '../styles/event-scan.css'

export default function EventScan() {
  const [user, loading, error] = useAuthState(auth);
  const [name, setName] = useState("");
  const [email,setEmail] = useState("");
  const [account,setAccount] = useState("");
  const [amount, setAmount] = useState(0);
  const [accountLink,setAccountLink] = useState("");
  const [loginLink,setLoginLink] = useState("");
  const [Delay, setDelay] = useState(100);
  const [result, setResult] = useState("");
  const [showQr, setQr] = useState(false);
  const [foundCustomer, setFoundCustomer] = useState(false);
  const [payments, setPayments] = useState([]);
  const [description, setDescription] = useState("");
  const [processedPayment, setProcessedPayment] = useState("");
  const [selection, setSelection] = useState("");
  const [products, setProducts] = useState([{name:"coat check", price:500}]);
  const [productID, setProductID] = useState("");
  const [variationID, setVariationID] = useState("");
  const [items, setItems] = useState([]);
  const [err, setErr] = useState("");
  const [isMobile, setIsMobile] = useState(false);
  const [coatNumber, setCoatNumber] = useState("");
  const [eventResult, setEventResult] = useState("");
  // const api = "https://us-central1-payme-eb477.cloudfunctions.net/app";
  // const api = "http://localhost:5001";
  const api = "https://rocky-eyrie-95465.herokuapp.com";


  const navigate = useNavigate();

  const addToCart = (item) =>{
    // console.log(item);
    setItems(prevState => [...prevState, item]);
  }

  const formatPrice = (price) =>{
    return `$${(price * .01).toFixed(2)}`;
}


  const totalPrice = (items) =>{
    return items.reduce((accum, item) =>accum + item.quantity * item.price, 0.0);
  }

  const trackQuantity = (items) =>{
    if(items === 'clear'){
      return [];
    } else{
      return items.reduce((accum, item) =>{
        let result = accum.find(_item => _item.name === item.name);
        if(result){
            result.quantity = result.quantity + 1;
        } else{
            accum.push({
                quantity: 1,
                ...item
            })
        }
        return accum;
    }, []); 
    }
}

  // const getProducts = async () =>{
  //   const response = await fetch(api + '/get-square-products', {
  //     method:"GET"
  //   })
  //   if(response.ok){
  //     const res = await response.json();
  //     setProducts(res.objects)
  //   }
  // }

  // const getDevice = async(device)=>{
  //   if (/Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(device)) {
  //     // return "mobile";
  //     console.log("mobile");
  //     isMobile(true);
  //   } else{
  //     console.log(device);
  //   }
  // }


  // const mode = 'rear';


  const handleScan = async(data)=>{

    if(data !== null){
      try{
        if(data.split(" ")[0].includes("cus") && data.split(" ")[1].length === 6){
          try{
            setFoundCustomer(true);
            setResult(data);

            const q = query(collection(db, "events"), where("event_code", "==", data.split(" ")[1]));

            const docz = await getDocs(q);
            if(docz.docs.length === 0){
              // return navigate("/login")
              console.log("nothing");
            }
      
            const info = {
              customer_id: data.split(" ")[0],
              event_code: data.split(" ")[1],
            }

            const response = await fetch(api + '/scan-event', {
              method:"POST",
              headers:{"Content-Type": "application/json"},
              body:JSON.stringify(info)
            })

            if(response.ok){
              const res = await response.json();
              console.log(res);
              // setProcessedPayment(res.id);

              if(res.result === 'customer was added'){
                setEventResult("Successful!")
              } else{
                setEventResult("Pass has already been used")
              }
              setTimeout(()=>{
                setFoundCustomer(false)
                setEventResult("");
                setQr(false)
              }, 1500)
            }
      

            // // console.log(info);

            // const response = await fetch(api + '/create-coat-charge', {
            //   method:"POST",
            //   headers:{"Content-Type": "application/json"},
            //   body:JSON.stringify(info)
            // })
      
            // if(response.ok){
            //   const res = await response.json();
            //   console.log(res);
            //   setProcessedPayment(res.id);
            //   setTimeout(()=>{
            //     setFoundCustomer(false)
            //     setItems([])
            //     setQr(false)
            //     setProcessedPayment('');
            //     setCoatNumber('');
            //   }, 1000)
            // }
          } catch(err){
            console.log(err)
          }
        } else{
          console.log("failure");
        }

      } catch(err){
        console.log(err)
      }
    } else{
      // setErr("")
    }
  }

  // const makeSelection = async(name, price, id, variation_id)=>{
  //   setDescription(name);
  //   setAmount(price);
  //   setSelection(name + " - " + price);
  //   setProductID(id);
  //   setVariationID(variation_id);
  // }

  const fetchUserInfo = async () => {
    try {
      const q = query(collection(db, "restaurants"), where("uid", "==", user?.uid));

      const docz = await getDocs(q);
      if(docz.docs.length === 0){
        return navigate("/login")
      }
      const data = docz.docs[0].data();
      setName(data.name);
      setEmail(data.email);

      if(data.account_id !== ""){
        setAccount(data.account_id);
        checkAccountID(data.account_id);
      } else{
        const response = await fetch('/create-account', {
          method:"POST",
          headers:{"Content-Type":"application/json"},
          body: JSON.stringify(data)
        })

        if(response.ok){
          const res = await response.json();
          console.log(res);
          const userDocRef = doc(db,'restaurants',docz.docs[0].id)
          await updateDoc(userDocRef, {
            account_id:res.id
          })
      }
      }
    } catch (err) {
      console.error(err);
      alert("An error occured while fetching user data");
    }
  };

  const checkAccountID = async(account_id) => {
    const data = {
      account_id: account_id
    }

    const response = await fetch(api + '/check-account', {
      method:"POST",
      headers:{"Content-Type":"application/json"},
      body:JSON.stringify(data)
    })

    if(response.ok){
      const res = await response.json();

      if(res.payouts_enabled){
        generateLoginLink(res.id);
      } else{
        generateAccountLink(res.id);
      }
    }
  }

  const toggleQR = async() =>{
    setQr(!showQr);
    console.log(showQr);
  }


  const generateLoginLink = async (account_id) =>{
    const data = {
      account_id: account_id
    }

    const response = await fetch(api + '/login-link', {
      method:"POST",
      headers:{"Content-Type":"application/json"},
      body:JSON.stringify(data)
    })

    if(response.ok){
      const res = await response.json();
      console.log(res);
      setLoginLink(res.url);
    }
  }


  const generateAccountLink = async (account_id) =>{
    const data = {
      account_id:account_id
    }

    const response = await fetch('/account-link',{
      method:"POST",
      headers:{"Content-Type":"application/json"},
      body:JSON.stringify(data)
    })

    if(response.ok){
      const res = await response.json();
      console.log(res);
      setAccountLink(res.url);
    }
  }

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/");
    fetchUserInfo();
    // getProducts();
  }, [user, loading]);

  const previewStyle = {
    height: 240,
    width: 300,
  }


  if(accountLink !== ""){
    navigate('/dashboard')
  } else{
    return (
      <div className="event-container">
        <Header />

        <div className="event-content">
            {showQr === false &&
              <button className="login-button" onClick={toggleQR}>Scan Event Code</button>
            }
            {foundCustomer === false && showQr === true && 
              <div className="reader">
                      <QrReader
                      delay={Delay}
                      style={previewStyle}
                      onScan={handleScan}
                      facingMode={"environment"}
                      /> 
              </div>
            }
            {eventResult !== '' && 
              <div><h1 className="success-payment">{eventResult}</h1></div>
            }
         </div>

         </div>
    )
  }
}
