import React, { useEffect, useState } from "react";
import "../styles/privacy.css"
import Header from '../components/header';

const PrivacyPolicy = () => {

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://app.termly.io/embed-policy.min.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);

    return (
      <div className='privacy-policy-container'>
        <Header />
        <div name="termly-embed" data-id="68a4dd6a-08c5-4af7-9087-2bfd27d017a4" data-type="iframe"></div>
      </div>
      
    );
  };
  
  export default PrivacyPolicy;