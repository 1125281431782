import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth, registerWithEmailAndPassword, signInWithGoogleRestaurant} from "../config/firebase-config";
import { useAuthState } from "react-firebase-hooks/auth";
import Header from '../components/header';
import '../styles/register.css'


function Register() {

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();


  const register = async (evt) =>{
    evt.preventDefault();
    const response = await registerWithEmailAndPassword(name,email,password);
    console.log(response);
  }

  useEffect(() => {
    if (loading) return;
    if (user) navigate("/dashboard");
  }, [user, loading]);


  return (
    <div className="register-container">
      <Header />
      <div className="register-content">
          <h1 className="header-text">Register/Login</h1>
            <button className="login-button" onClick={signInWithGoogleRestaurant}>
                Sign in with Google
            </button>
            {/* <div className="register-prompt">Already have an account? <Link to="/">Login</Link> now.</div> */}
        </div>
    </div>
  );
}

export default Register;