import Payment from "../pages/payment";
import Register from "../pages/register";
import Dashboard from "../pages/dashboard";
import CoatPayment from "../pages/coat-payment";
import EventScan from "../pages/event-scan";
import Privacy from "../pages/privacy";
import Transactions from "../pages/transactions";
import Insights from "../pages/insights";
import Reset from "../pages/reset";
import { BrowserRouter, Route, Routes } from "react-router-dom";

function App() {
  return (
    <BrowserRouter>
      <Routes>
          {/* <Route path="/" element={<Home />} /> */}
          {/* <Route path="/faq" element={<FAQ />} /> */}
          {/* <Route path="/login" element={<Login />} /> */}
          <Route path="/payment" element={<Payment />} />
          <Route path="/coat-payment" element={<CoatPayment />} />
          <Route path="/event-scan" element={<EventScan />} />
          <Route path="/" element={<Register />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/insights" element={<Insights />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/transactions" element={<Transactions />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;